// Utilities
import {
  camelCase,
  upperFirst,
} from 'lodash'

export default function (sections = []) {
  return {
    name: 'LoadSections',

    // Iterates the provide list of components
    // and returns a function that returns a
    // Promise.
    components: sections.reduce((acc, cur) => {
      const filename = upperFirst(camelCase(cur.slice(cur.lastIndexOf('/') + 1, cur.length)))
      const folder = cur.slice(0, cur.lastIndexOf('/') + 1)
      const name = upperFirst(camelCase(cur)).replace(/\//g, '-')

      console.log(`file is ${filename}, folder is "${folder}" and name is ${name}.`)
      acc[`Section${name}`] = () => import(`@/views/${folder}${filename}.vue`)

      return acc
    }, {}),

    data: () => ({ sections: sections.map(x => x.replace(/\//g, '-')) }),
  }
}
